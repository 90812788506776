.content-app{ 
  max-width: 1000px;
  margin: 0 auto;
  padding: 30px;
}

.text-right{
  text-align: right;
}
.text-center{
  text-align: center;
}
.text-left{
  text-align: left;
}
.no-margin{
  margin: 0 !important;
}

